$(document).ready(function() {
  validarInteraccionesScroll();
  $(window).resize(function() {
    validateResize();
    validarInteraccionesScroll();
  })
  $(window).scroll(function() {
    validarInteraccionesScroll();
  })
});

const validateResize = () => {
  if(window.innerWidth > 767){
    // RESET MENU PRINCIPAL Y CHATBOT
    $('#mainMenuPsa').collapse('hide');
    resetIconMenuPrincipal();
    
    $('#collapseFullChatbot').collapse('hide');
    $('#chatbot-btn').removeClass('d-none');
  }
} 

const scrollStatus = () => {
  $('#mainMenuPsa').on('show.bs.collapse', function () {
    if(window.innerWidth < 991){
      $('body').addClass('stop-scrolling');
    }
  });
  $('#mainMenuPsa').on('hide.bs.collapse', function () {
    $('body').removeClass('stop-scrolling');
  });


  $('#collapseFullChatbot').on('show.bs.collapse', function () {
    if(window.innerWidth < 767){
      $('body').addClass('stop-scrolling');
    }
  });
  $('#collapseFullChatbot').on('hide.bs.collapse', function () {
    $('body').removeClass('stop-scrolling');
  });
}
scrollStatus();

const resetIconMenuPrincipal = () => {
  $('#icon-menu-drop').removeClass("icon-menu-mobile-close")
  $('#icon-menu-drop').addClass("icon-menu-mobile-default")
}

const activeIconMenuPrincipal= () => {
  $('#icon-menu-drop').removeClass("icon-menu-mobile-default")
  $('#icon-menu-drop').addClass("icon-menu-mobile-close")
}

const validarInteraccionesNavMobile = () =>{
  if ($("#mainMenuPsa").hasClass("show")){
    $('header').removeClass("collapse-show");
    resetIconMenuPrincipal();

    $('#collapsePruebasMobile').collapse('hide')

    $('#icon-drop-main-menu-xs').removeClass("icon-arrow-up")
    $('#icon-drop-main-menu-xs').addClass("icon-arrow-down")

    $('#collapse-xs-01').removeClass('active')

    scrollStatus();

    $("#mainMenuPsa").removeClass('overflow-nav-principal');
  }
  else{
    $('header').addClass("collapse-show");
    // $('#icon-menu-drop').prop("src","./build/img/icon-close-menu-hamburguesa.webp")
    activeIconMenuPrincipal();

    scrollStatus();

    $("#mainMenuPsa").addClass('overflow-nav-principal');
    // $('#icon-drop-main-menu-xs').removeClass("icon-arrow-up")
    // $('#icon-drop-main-menu-xs').addClass("icon-arrow-down")
  }
}

// Validar interacciones scroll
const obtenerAltoHeader = () => {
  const offsetHeader = $("header").outerHeight();
  return offsetHeader;
}

const validarInteraccionesScroll = () => {
  // let offsetNav = $("#offset-nav-interactions").height();
  // let offsetHeader = $("header").outerHeight();
  let offsetMain = $(".offset-slide-main-home-psa").outerHeight();
  let offsetTotal = obtenerAltoHeader() + offsetMain;

  if ($(document).scrollTop() > 0 && $(document).scrollTop() < offsetMain){
  // if ($(document).scrollTop() > 0){
    $('#collapsePruebasWebFixed').collapse('hide')
    
    $('#icon-drop-main-menu-fixed').removeClass("icon-arrow-up")
    $('#icon-drop-main-menu-fixed').addClass("icon-arrow-down")
    $('#collapse-fixed-01').removeClass('active')

    $("#offset-nav-interactions").css("height",obtenerAltoHeader()+"px")

    detectarMouseoverCollapse(obtenerAltoHeader());
    detectarMouseleaveCollapse(obtenerAltoHeader()); 
  } 
  
  if($(window).scrollTop() > offsetTotal) {
    $('#collapsePruebasWebFixed').collapse('hide')
    
    $('#icon-drop-main-menu-fixed').removeClass("icon-arrow-up")
    $('#icon-drop-main-menu-fixed').addClass("icon-arrow-down")
    $('#collapse-fixed-01').removeClass('active')

    $("header").addClass("fixed")
    $("header").addClass("animation-nav")

    $("#offset-nav-interactions").css("height",obtenerAltoHeader()+"px")

    detectarMouseoverCollapse(obtenerAltoHeader());
    detectarMouseleaveCollapse(obtenerAltoHeader()); 
  } 
  else{
    $("header").removeClass("fixed")
    $("header").removeClass("animation-nav")

    $("#offset-nav-interactions").css("height",obtenerAltoHeader()+"px")

    detectarMouseoverCollapse(obtenerAltoHeader());
    detectarMouseleaveCollapse(obtenerAltoHeader()); 
  }
}


// Detectar mouseover collapse fixed web
const detectarMouseoverCollapse = (offset) => {
  $('#collapse-fixed-01').mouseover(function(){
    $('#collapsePruebasWebFixed').collapse('show')
    // $('#icon-drop-main-menu-fixed').prop("src","./build/img/icon-contraer.webp")
    $('#icon-drop-main-menu-fixed').removeClass("icon-arrow-down")
    $('#icon-drop-main-menu-fixed').addClass("icon-arrow-up")
    $('#collapse-fixed-01').addClass('active')
  
    $(".collapse-menu-principal-fixed").css("top",offset+"px")
  })
}

// Detectar mouseleave collapse fixed web
const detectarMouseleaveCollapse = (offset) => {
  $('#offset-nav-interactions').mouseleave(function(){
    $('#collapsePruebasWebFixed').collapse('hide')
    // $('#icon-drop-main-menu-fixed').prop("src","./build/img/icon-desplegar.webp")
    $('#icon-drop-main-menu-fixed').removeClass("icon-arrow-up")
    $('#icon-drop-main-menu-fixed').addClass("icon-arrow-down")
    $('#collapse-fixed-01').removeClass('active')
  
    $(".collapse-menu-principal-fixed").css("top",offset+"px")
  })
}

$("#btn-main-menu-xs, #cta-main-menu-mobile").click(function () {
  validarInteraccionesNavMobile();
});

// Change Icon Arrow dropdown menu XS
$("#collapse-xs-01").click(function () {
  if ($("#collapsePruebasMobile").hasClass("show")){
    $('#icon-drop-main-menu-xs').removeClass("icon-arrow-up")
    $('#icon-drop-main-menu-xs').addClass("icon-arrow-down")
    // $('#icon-drop-main-menu-xs').prop("src","./build/img/icon-desplegar-xs.webp")
    $('#collapse-xs-01').removeClass('active')
  }
  else{
    $('#icon-drop-main-menu-xs').removeClass("icon-arrow-down")
    $('#icon-drop-main-menu-xs').addClass("icon-arrow-up")
    // $('#icon-drop-main-menu-xs').prop("src","./build/img/icon-contraer-xs.webp")
    $('#collapse-xs-01').addClass('active')
  }
});

// Change Icon Arrow dropdown fixed web
$("#collapse-fixed-01").click(function () {
  if ($("#collapsePruebasWebFixed").hasClass("show")){
    // $('#icon-drop-main-menu-fixed').prop("src","./build/img/icon-desplegar.webp")
    $('#icon-drop-main-menu-fixed').removeClass("icon-arrow-up")
    $('#icon-drop-main-menu-fixed').addClass("icon-arrow-down")
    $('#collapse-fixed-01').removeClass('active')
  }
  else{
    // $('#icon-drop-main-menu-fixed').prop("src","./build/img/icon-contraer.webp")
    $('#icon-drop-main-menu-fixed').removeClass("icon-arrow-down")
    $('#icon-drop-main-menu-fixed').addClass("icon-arrow-up")
    $('#collapse-fixed-01').addClass('active')
  }
});

// Vocacional Interaction
const btnVocacionalDesktop = document.querySelector('#vocacional-xl');
const btnVocacionalMobile = document.querySelector('#vocacional-xs');

// XS
if(btnVocacionalDesktop){
  btnVocacionalMobile.parentElement.addEventListener('mouseover', () => {
    btnVocacionalMobile.setAttribute('src', '/build/img/prueba-vocacional-xs-hover.svg')
  });
  btnVocacionalMobile.parentElement.addEventListener('mouseleave', () => {
    btnVocacionalMobile.setAttribute('src', '/build/img/prueba-vocacional-xs.svg')
  });
}

if(btnVocacionalMobile){
  // XL
  btnVocacionalDesktop.parentElement.addEventListener('mouseover', () => {
    btnVocacionalDesktop.setAttribute('src', '/build/img/prueba-vocacional-hover.svg')
  });
  btnVocacionalDesktop.parentElement.addEventListener('mouseleave', () => {
    btnVocacionalDesktop.setAttribute('src', '/build/img/prueba-vocacional.svg')
  });
}




/* PRUEBAS PSA */

//Intercalar imagenes slider 02 A web

// $('#slider-web-02-a').on('slide.bs.carousel', function onSlide (ev) {
//   var id = ev.relatedTarget.id;
//   // console.log(id) 
//   // console.log(ev.direction)
  
//   switch (id) {
//     case "item-slider-02-01-a":
//       $('#tx-slider-02-02-a').removeClass('active')
//       $('#tx-slider-02-03-a').removeClass('active')
//       $('#tx-slider-02-01-a').addClass('active')

//       $('#icon-slider-02-02-a').removeClass('on')
//       $('#icon-slider-02-03-a').removeClass('on')
//       $('#icon-slider-02-01-a').addClass('on')
//       break;
//     case "item-slider-02-02-a":
//       $('#tx-slider-02-01-a').removeClass('active')
//       $('#tx-slider-02-03-a').removeClass('active')
//       $('#tx-slider-02-02-a').addClass('active')

//       $('#icon-slider-02-01-a').removeClass('on')
//       $('#icon-slider-02-03-a').removeClass('on')
//       $('#icon-slider-02-02-a').addClass('on')
//       break;
//     default:
//       $('#tx-slider-02-01-a').removeClass('active')
//       $('#tx-slider-02-02-a').removeClass('active')
//       $('#tx-slider-02-03-a').addClass('active')

//       $('#icon-slider-02-01-a').removeClass('on')
//       $('#icon-slider-02-02-a').removeClass('on')
//       $('#icon-slider-02-03-a').addClass('on')
//       break;
//   }
// })

//Intercalar imagenes slider 02 web
// $('#slider-web-02').on('slide.bs.carousel', function onSlide (ev) {
//   var id = ev.relatedTarget.id;
//   // console.log(id) 
//   // console.log(ev.direction)
  
//   switch (id) {
//     case "item-slider-02-01":
//       $('#tx-slider-02-02').removeClass('active')
//       $('#tx-slider-02-03').removeClass('active')
//       $('#tx-slider-02-01').addClass('active')

//       $('#icon-slider-02-02').removeClass('on')
//       $('#icon-slider-02-03').removeClass('on')
//       $('#icon-slider-02-01').addClass('on')
//       break;
//     case "item-slider-02-02":
//       $('#tx-slider-02-01').removeClass('active')
//       $('#tx-slider-02-03').removeClass('active')
//       $('#tx-slider-02-02').addClass('active')

//       $('#icon-slider-02-01').removeClass('on')
//       $('#icon-slider-02-03').removeClass('on')
//       $('#icon-slider-02-02').addClass('on')
//       break;
//     default:
//       $('#tx-slider-02-01').removeClass('active')
//       $('#tx-slider-02-02').removeClass('active')
//       $('#tx-slider-02-03').addClass('active')

//       $('#icon-slider-02-01').removeClass('on')
//       $('#icon-slider-02-02').removeClass('on')
//       $('#icon-slider-02-03').addClass('on')
//       break;
//   }
// })



// REDIRECT FOOTER
const btnSoporteCandidatos = document.querySelector('#btn-soporte-canditatos');
// const btnSendMail = document.querySelector('#btn-send-mail');

if(btnSoporteCandidatos){
  btnSoporteCandidatos.addEventListener('click', () => {
    location.href = "/soporte-candidatos.php";
  });
}


// if(btnSendMail){
  // btnSendMail.addEventListener('click', () => {
  //   location.href = "mailto:soporte@psicoalianza.com";
  // });
// }

/* PRUEBAS PSA */
//Intercalar imagenes slider 02 A web
$('#slider-web-02-a').on('slide.bs.carousel', function onSlide (ev) {
  var id = ev.relatedTarget.id;
  // console.log(id) 
  // console.log(ev.direction)
  
  switch (id) {
    case "item-slider-02-01-a":
      $('#tx-slider-02-02-a').removeClass('active')
      $('#tx-slider-02-03-a').removeClass('active')
      $('#tx-slider-02-01-a').addClass('active')

      $('#icon-slider-02-02-a').removeClass('on')
      $('#icon-slider-02-03-a').removeClass('on')
      $('#icon-slider-02-01-a').addClass('on')
      break;
    case "item-slider-02-02-a":
      $('#tx-slider-02-01-a').removeClass('active')
      $('#tx-slider-02-03-a').removeClass('active')
      $('#tx-slider-02-02-a').addClass('active')

      $('#icon-slider-02-01-a').removeClass('on')
      $('#icon-slider-02-03-a').removeClass('on')
      $('#icon-slider-02-02-a').addClass('on')
      break;
    default:
      $('#tx-slider-02-01-a').removeClass('active')
      $('#tx-slider-02-02-a').removeClass('active')
      $('#tx-slider-02-03-a').addClass('active')

      $('#icon-slider-02-01-a').removeClass('on')
      $('#icon-slider-02-02-a').removeClass('on')
      $('#icon-slider-02-03-a').addClass('on')
      break;
  }
})

//Intercalar imagenes slider 02 web
$('#slider-web-02').on('slide.bs.carousel', function onSlide (ev) {
  var id = ev.relatedTarget.id;
  // console.log(id) 
  // console.log(ev.direction)
  
  switch (id) {
    case "item-slider-02-01":
      $('#tx-slider-02-02').removeClass('active')
      $('#tx-slider-02-03').removeClass('active')
      $('#tx-slider-02-01').addClass('active')

      $('#icon-slider-02-02').removeClass('on')
      $('#icon-slider-02-03').removeClass('on')
      $('#icon-slider-02-01').addClass('on')
      break;
    case "item-slider-02-02":
      $('#tx-slider-02-01').removeClass('active')
      $('#tx-slider-02-03').removeClass('active')
      $('#tx-slider-02-02').addClass('active')

      $('#icon-slider-02-01').removeClass('on')
      $('#icon-slider-02-03').removeClass('on')
      $('#icon-slider-02-02').addClass('on')
      break;
    default:
      $('#tx-slider-02-01').removeClass('active')
      $('#tx-slider-02-02').removeClass('active')
      $('#tx-slider-02-03').addClass('active')

      $('#icon-slider-02-01').removeClass('on')
      $('#icon-slider-02-02').removeClass('on')
      $('#icon-slider-02-03').addClass('on')
      break;
  }
})
